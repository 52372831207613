import React, { useContext } from "react";

import EventImage from "../assignedMatch/eventImage";
import CardList from "../common/cardView/cardList";
import TeamLogo from "../common/pageComponents/teamLogo";
import { CLink } from "../common/customs/customLinks";
import { getCurrentUser } from "../../services/userService";
import OrgContext from "../../context/orgContext";
import { isAdmin, isCaptain } from "../../utils/permissionsUtil";
import TooltipButton from "../common/tooltip/tooltipButton";
import allowables from "../../utils/allowables";
import IconRender from "../common/icons/iconRender";
import Tooltip from "../common/tooltip/tooltip";

const PlayersCardView = ({ players, onDelete, isSpectate, onRecruit }) => {
  const org = useContext(OrgContext);
  const user = getCurrentUser();

  const extraFields =
    players[0]?.sport === "Soccer"
      ? [
          {
            header: "Unserved Suspension",
            text: (player) => player.suspendedFlag,
          },
          {
            header: "Accumulated Yellows",
            text: (player) => player.runningYellows || 0,
            neverHide: true,
          },
          {
            header: "Waiver Wire Expires",
            text: (player) =>
              player.teamName !== "Free Agents" ? null : player.waiverWire &&
                player.waiverWire.expiration ? (
                <Tooltip
                  overrideStyle={{ width: 250 }}
                  content={
                    <span>
                      <h6>
                        <b>Waiver Wire Information</b>
                      </h6>
                      <ul>
                        <li>
                          To recruit a player on the waiver wire you must place
                          a bid. You can only have one bid active at any time.
                        </li>
                        <li>
                          Once the waiver wire has expired the team with the
                          highest waiver priority who placed a bid will recruit
                          that player. If there are no bids then any team
                          captain may recruit that player going forward.
                        </li>
                        <li>
                          Waiver priority is first assigned when you place your
                          bid for a player or when the first waiver for the
                          league expires. The team that wins the waiver is
                          placed at the bottom of the priority list.
                        </li>
                      </ul>
                    </span>
                  }
                  direction="right"
                >
                  <span className="text-center">
                    {allowables.shortDate(player.waiverWire.expiration, true)}
                  </span>
                </Tooltip>
              ) : (
                "Open"
              ),
          },
        ]
      : [];

  const waiverWireButtons =
    org?.misc?.waiverWireOn && isCaptain(user)
      ? [
          {
            displayFunc: (player) => {
              return player.teamName === "Free Agents" ? (
                <TooltipButton
                  className="btn btn-sm btn-info m-1"
                  onClick={() => onRecruit(player)}
                  tooltipText={`Recruit ${allowables.splitName(player.name)}`}
                  tooltipDirection="left"
                >
                  <IconRender name="recruit" />
                </TooltipButton>
              ) : null;
            },
          },
        ]
      : [];

  return (
    <CardList
      noLinks={isSpectate}
      items={players}
      onDelete={isAdmin(user) ? onDelete : null}
      unshiftFields={[
        {
          content: (player) => <EventImage player={player} />,
        },
        {
          content: (player) => (
            <TeamLogo team={player.teamLogo} name={player.teamName} size={40} />
          ),
        },
      ]}
      fields={[
        {
          header: "Team",
          text: (player) =>
            player.teamName === "Free Agents" ? (
              player.teamName
            ) : (
              <CLink
                asSpan={true}
                path={`/teams?info&q=${player.teamID?._id || player.teamID}`}
              >
                {player.teamName}
              </CLink>
            ),
        },
        {
          header: "Number",
          text: (player) => player.shirtNumber,
        },
        ...extraFields,
      ]}
      extraRightSideItems={waiverWireButtons}
      editPath={isAdmin(user) ? "/players" : ""}
    />
  );
};

export default PlayersCardView;
