import React, { useContext } from "react";

import TeamLogo from "../common/pageComponents/teamLogo";
import allowables from "../../utils/allowables";
import CardList from "../common/cardView/cardList";
import { CLink, navigateTo } from "../common/customs/customLinks";
import { getTextColor } from "ultimatescoreboard-shared";
import { useHistory } from "react-router-dom";
import HeaderContext from "../../context/headerContext";

const TeamsCardView = ({ teams, onDelete, onPurge, isSpectate }) => {
  const history = useHistory();
  const header = useContext(HeaderContext);
  return (
    <CardList
      noLinks={isSpectate}
      items={teams}
      nameOverrideFunc={(team) => {
        const color = getTextColor(team.teamColor1);
        return (
          <div
            className="cardview-pop clickable"
            onClick={
              isSpectate
                ? null
                : () => navigateTo(`/teams?info&q=${team._id}`, history, header)
            }
            style={{ color, backgroundColor: team.teamColor1 }}
          >
            <div
              style={{
                display: "flex",
                fontWeight: "bold",
              }}
            >
              <div
                style={{ flex: "90%", marginLeft: 5 }}
                className="align-self-center"
              >
                {team.name}
              </div>
              <div
                style={{ flex: "10%", marginLeft: 5 }}
                className="align-self-center"
              >
                {team.teamColor2 ? (
                  <div
                    className="color-square"
                    style={{
                      backgroundColor: team.teamColor2,
                      width: "50%",
                      margin: 0,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      }}
      unshiftFields={[
        {
          content: (team) => (
            <TeamLogo team={team} name={team.name} size={55} />
          ),
        },
      ]}
      fields={[
        {
          header: "Captain",
          text: (team) =>
            team.teamCaptainID ? (
              isSpectate ? (
                allowables.splitName(team.teamCaptainID.name)
              ) : (
                <CLink
                  path={`/players?info&q=${team.teamCaptainID.playerID}`}
                  asSpan={true}
                >
                  {allowables.splitName(team.teamCaptainID.name)}
                </CLink>
              )
            ) : null,
        },
        {
          header: "Division",
          text: (team) =>
            isSpectate ? (
              team.divisionName
            ) : (
              <CLink
                path={`/divisions?info&q=${
                  team.divisionID?._id || team.divisionID
                }`}
                asSpan={true}
              >
                {team.divisionName}
              </CLink>
            ),
        },
        { header: "Seed", text: (team) => team.seed },
        { header: "Group", text: (team) => team.groupName },
      ]}
      onDelete={onDelete}
      onPurge={onPurge}
      editPath="/teams"
    />
  );
};

export default TeamsCardView;
