import React from "react";
import CardList from "../common/cardView/cardList";
import BoolVis from "../common/icons/booleanRep";
import { CLink } from "../common/customs/customLinks";

const DivisionsCardView = ({ divisions, onDelete }) => {
  return (
    <CardList
      items={divisions}
      fields={[
        {
          header: "Season",
          text: (division) =>
            division.seasonID ? (
              <CLink
                asSpan={true}
                path={`/seasons?info&q=${division.seasonID?._id}`}
              >
                {division.seasonID?.name}
              </CLink>
            ) : null,
        },
        {
          header: "Maximum Players per Team",
          text: (division) => division.maxPlayersPerTeam,
        },
        {
          header: "Editing Locked",
          text: (division) => <BoolVis bool={division.editingLocked} />,
        },
        {
          header: "Rosters Locked",
          text: (division) => <BoolVis bool={division.rostersLocked} />,
        },
      ]}
      onDelete={onDelete}
      editPath={"/divisions"}
    />
  );
};

export default DivisionsCardView;
