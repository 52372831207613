import React, { useState, useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import ReactGA from "react-ga4";
import { IconContext } from "react-icons";
import { logout } from "../services/userService";
import { cancelMatchInProgress } from "../utils/liveScoresUtil";
import { NavDropdown } from "react-bootstrap";
import OrgLogo from "./common/pageComponents/orgLogo";
import CustomConfirm from "./common/customs/customConfirm";
import HeaderContext from "../context/headerContext";
import { navigateTo } from "./common/customs/customLinks";
import MainTitle from "./common/loading/mainTitle";
import IconRender from "./common/icons/iconRender";
import { getAccountText } from "../utils/textUtils";
import Tooltip from "./common/tooltip/tooltip";
import NewFeatureBadge from "./common/pageComponents/newFeatureBadge";
import OutsideClickHandler from "react-outside-click-handler";
import { isManager, isOwner } from "../utils/permissionsUtil";

const CustomNavDropdown = ({ items, label, id, isUserDropdown }) => {
  const header = useContext(HeaderContext);
  let history = useHistory();

  const wrapBold = (itemLabel) => <b>{itemLabel}</b>;

  return (
    <NavDropdown
      title={label}
      onClick={() => header.setAllowStickyItems(false)}
      id={id}
      style={isUserDropdown ? { marginLeft: "auto" } : {}}
    >
      {items.map((item, idx) => {
        if (item.dontDisplay) return null;
        if (item.nested) return item.Component;
        return (
          <NavDropdown.Item
            key={label + idx}
            onSelect={() =>
              item.path
                ? navigateTo(item.path, history, header)
                : item.onSelect()
            }
            className="high-z nav-item"
          >
            <IconRender name={item.iconName} />{" "}
            {item.bold ? wrapBold(item.label) : item.label}{" "}
            {item.new ? <NewFeatureBadge /> : null}
          </NavDropdown.Item>
        );
      })}
    </NavDropdown>
  );
};

const NavBar = (props) => {
  const header = useContext(HeaderContext);
  let history = useHistory();
  const [dialog, setDialog] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [switchAccountOpen, setSwitchAccountOpen] = useState(false);
  const [clickedAccount, setClickedAccount] = useState(null);

  const user = props.user;
  if (!user) {
    return (
      <div
        className="text-center light-bg cardview-pop clickable"
        onClick={() => navigateTo("/", history, header)}
        style={{ marginBottom: 15 }}
      >
        {header.pageHeader ? (
          <OrgLogo _id={header.pageHeader} />
        ) : (
          <MainTitle maxWidth={1000} minWidthForPortrait={500} />
        )}
      </div>
    );
  }
  let role = "none";
  let orgID = "";
  if (user) {
    role = user.role;
    orgID = user.orgID;
    user.firstName = user.firstName
      ? user.firstName
      : user.name?.split("%20%")[0];
    user.lastName = user.lastName ? user.lastName : user.name?.split("%20%")[1];
  }

  const switchAccountCheck = (account) => {
    if (localStorage.getItem("matchStarted")) {
      setClickedAccount(account);
      return setSwitchAccountOpen(true);
    }
    header.switchAccountInUse(account);
  };

  const renderLogo = () => {
    return (
      <Tooltip direction="right" content={<span>{props.org?.name}</span>}>
        <OrgLogo _id={props.org?.logo} name={props.org?.name} />
      </Tooltip>
    );
  };

  const restickyTops = () => {
    header.setAllowStickyItems(true);
  };

  const doLogout = async () => {
    header.setLoading(true);
    header.setProgress([0]);
    await cancelMatchInProgress();
    logout();
    ReactGA.event({
      category: "User",
      action: "logged out",
      label: "clicked logout from navbar",
      nonInteraction: false,
    });
    window.location = "/";
  };

  return (
    <IconContext.Provider value={{ className: "icon-raised" }}>
      <OutsideClickHandler
        onOutsideClick={() => header.setAllowStickyItems(true)}
      >
        <nav
          className={"navbar light-bg cardview-pop"}
          style={{ marginBottom: 10 }}
        >
          {/* Top Left League Link */}
          {user &&
          ((user.role?.includes("admin") && !user.orgID) ||
            isManager(user) ||
            props.org?.spectatingAllowed) &&
          !user.role?.includes("super") ? (
            <NavLink
              className="nav-item nav-link"
              to={
                isManager(user) || (user.role?.includes("admin") && !user.orgID)
                  ? "league"
                  : props.org?.spectatingAllowed
              }
              onClick={() =>
                navigateTo(
                  isManager(user) ||
                    (user.role?.includes("admin") && !user.orgID)
                    ? "league"
                    : props.org?.spectatingAllowed,
                  history,
                  header
                )
              }
            >
              {props.org?.logo
                ? renderLogo()
                : props.org?.name || "Create League"}
            </NavLink>
          ) : props.org ? (
            <div className="navbar-header">
              <b>
                {props.org && props.org?.logo ? renderLogo() : props.org?.name}
              </b>
            </div>
          ) : null}

          {/* Super Admin View */}
          {user?.role?.includes("super") ? (
            <NavLink
              to="superadminview"
              className="nav-item nav-link"
              onClick={() => navigateTo("superadminview", history, header)}
            >
              Super Admin Helpers
            </NavLink>
          ) : null}

          {/* Referee Only sees their own matches */}
          {user && orgID && user.role?.includes("referee") && (
            <NavLink
              className="nav-item nav-link"
              to={"matches?type=my%20matches"}
              onClick={() =>
                navigateTo("matches?type=my%20matches", history, header)
              }
            >
              Scorecards
            </NavLink>
          )}

          {/* Manager Only Routes */}
          {isManager(user) && orgID ? (
            <CustomNavDropdown
              label={"Admin"}
              items={[
                {
                  label: "League Dues",
                  iconName: "payment",
                  path: "payments",
                },
                {
                  label: "Off Cycle Payments",
                  iconName: "contactlesspayment",
                  path: "offcyclepayments",
                  new: true,
                },
                {
                  label: "Discounts",
                  iconName: "discount",
                  path: "discounts",
                },
                {
                  label: "Organization Page",
                  iconName: "building",
                  path: `organization?q=${props.org?.umbrellaID}`,
                  new: true,
                  dontDisplay: !props.org?.umbrellaID || !isOwner(user),
                },
                {
                  label: "League Webpage",
                  iconName: "web",
                  path: `${props.org?.spectatingAllowed}?edit`,
                  dontDisplay:
                    !props.org?.spectatingAllowed ||
                    props.org?.spectatingAllowed === "blocked",
                },
                {
                  label: "User Information",
                  iconName: "info",
                  path: "userinformation",
                },
                {
                  label: "Code of Conduct",
                  iconName: "signature",
                  path: "codeofconduct",
                },
                {
                  label: "Vaccine",
                  iconName: "vaccine",
                  path: "vaccine",
                  dontDisplay: props.org?.requireVaccine !== "Yes",
                },
                ...["customFlag1", "customFlag2"].map((flag, idx) => ({
                  label: props.org?.misc[flag],
                  iconName: "flag",
                  path: `customflags?q=${idx + 1}`,
                  dontDisplay: !props.org?.misc[flag],
                })),
              ]}
            />
          ) : null}

          {/* Non Referee Info Routes */}
          {user && role && !role?.includes("referee") && orgID ? (
            <CustomNavDropdown
              label={"View"}
              items={[
                {
                  label: "Scoreboards",
                  iconName: "scoreboard",
                  path: "scoreboard",
                  dontDisplay: !role?.includes("admin"),
                },
                {
                  label: "Fields",
                  iconName: "soccerField",
                  path: "fields",
                  dontDisplay: !role?.includes("admin"),
                },
                {
                  label: "Seasons",
                  iconName: "season",
                  path: "seasons",
                },
                {
                  label: "Divisions",
                  iconName: "division",
                  path: "divisions",
                },
                {
                  label: "Teams",
                  iconName: "team",
                  path: "teams",
                },
                {
                  label: "Players",
                  iconName: "users",
                  path: "players",
                },
                {
                  label: "Standings",
                  iconName: "table",
                  path: "reports?standings=",
                },
                {
                  label: "Playoff Brackets",
                  iconName: "bracket",
                  path: "reports?brackets=",
                },
                {
                  label: "Leaderboards",
                  iconName: "leaderboard",
                  path: "reports?leaderboards=",
                },
                {
                  label: "League Archive",
                  iconName: "archive",
                  path: "archive?type=standings",
                },
              ]}
            />
          ) : null}

          {/* Non Referee Match Related Routes */}
          {user && user.role && !user.role?.includes("referee") && orgID ? (
            <CustomNavDropdown
              label={"Scorecards"}
              items={[
                {
                  label: "Create Schedule",
                  iconName: "plus",
                  path: "create",
                  dontDisplay: !user.role?.includes("admin"),
                },
                {
                  label: "In Progress",
                  iconName: "live",
                  path: "scorecards",
                  dontDisplay: !props.org?.misc?.liveScorecards,
                },
                {
                  label: "Upcoming",
                  iconName: "calendar",
                  path: "matches",
                },
                {
                  label: "Complete",
                  iconName: "clipboard",
                  path: "matchreview",
                },
              ]}
            />
          ) : null}

          {/* User Profile and Misc Items */}
          {user ? (
            <CustomNavDropdown
              id="user-dropdown"
              isUserDropdown={true}
              label={
                <i>
                  {user.name?.split("%20%")[0] || ""}{" "}
                  <IconRender name="userSettings" />
                </i>
              }
              items={[
                {
                  label: "Create My Team",
                  iconName: "team",
                  path: "teams?q=new",
                  bold: true,
                  dontDisplay: !user.role?.includes("captain") || user.teamID,
                },
                {
                  label: "Profile",
                  iconName: "userEdit",
                  path: "profile",
                },
                {
                  label: "Subscription",
                  iconName: "stripe",
                  path: "subscriptions",
                  dontDisplay: !isManager(user),
                },
                {
                  nested: true,
                  dontDisplay:
                    !user.role?.includes("player") ||
                    !user.teamID ||
                    user.teamName === "Free Agents",
                  Component: (
                    <CustomNavDropdown
                      key={"team-1"}
                      label={
                        <i>
                          <IconRender name="team" /> My Team
                        </i>
                      }
                      items={[
                        {
                          label: "Team Info",
                          iconName: "info",
                          path: `teams?info&q=${user.teamID}`,
                        },
                        {
                          label: "Add Players",
                          iconName: "userAdd",
                          path: `addplayers`,
                          dontDisplay: !user?.role?.includes("captain"),
                        },

                        {
                          label: "Message Center",
                          iconName: "email",
                          path: "messages",
                          new: true,
                        },
                        {
                          label: "Tactics",
                          iconName: "soccerField",
                          path: "tactics",
                          dontDisplay: user.sport !== "Soccer",
                          new: true,
                        },
                      ]}
                    />
                  ),
                },
                ...(props.org?.misc?.externalLinks?.length
                  ? props.org.misc.externalLinks
                  : []
                ).map((l) => ({
                  label: l.text,
                  iconName: "externallink",
                  onSelect: () => window.open(l.url),
                })),
                {
                  nested: true,
                  dontDisplay: header.userAccounts.length <= 1,
                  Component: (
                    <CustomNavDropdown
                      id="accounts-dropdown"
                      key="switch-1"
                      label={
                        <i>
                          <IconRender name="switch" /> Switch Account
                        </i>
                      }
                      items={[
                        ...header.userAccounts.map((ua, idx) => {
                          return {
                            label: getAccountText(ua, idx),
                            onSelect: () => switchAccountCheck(ua),
                            dontDisplay: ua._id === user._id,
                          };
                        }),
                      ]}
                    />
                  ),
                },
                {
                  label: "My History",
                  iconName: "history",
                  path: "history",
                  dontDisplay: !user.role?.includes("player"),
                },
                { label: "Help & FAQs", iconName: "help", path: "help" },
                {
                  label: "Log Out",
                  iconName: "logout",
                  onSelect: () => {
                    header.setAllowStickyItems(false);
                    setDialog("Are you sure you want to log out?");
                    setConfirmOpen(true);
                  },
                },
              ]}
            />
          ) : null}
          {/* Page Header */}
          {user && header.pageHeader ? (
            <div className="navbar-title">
              <h6 style={{ margin: 0 }}>
                <b>{header.pageHeader}</b>
              </h6>
            </div>
          ) : null}
        </nav>

        <CustomConfirm
          dialog={dialog}
          callback={doLogout}
          isOpen={confirmOpen}
          close={setConfirmOpen}
          focused={true}
          yesNo={true}
          negativeCallback={restickyTops}
          alsoRunOnClose={restickyTops}
        />
        <CustomConfirm
          dialog="You have a scorecard in progress. Switching accounts will cause all data to be lost. Are you sure?"
          callback={() => header.switchAccountInUse(clickedAccount)}
          isOpen={switchAccountOpen}
          close={setSwitchAccountOpen}
          focused={true}
          yesNo={true}
        />
      </OutsideClickHandler>
    </IconContext.Provider>
  );
};

export default NavBar;
